import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"

const Gallery = () => {
  return (
    <Layout>
      <article>
        <h1>Gallery</h1>
        <p>
          I am currently <i>not taking orders for custom ironwork</i> until
          further notice. Thank you for your past support. You can still enjoy
          the gallery.
        </p>
        <ul style={{ marginTop: "1em" }}>
          <li>
            <Link to="/gallery/branding-irons">Branding Irons</Link>
          </li>
          <li>
            <Link to="/gallery/candle-holders">Candle Holders</Link>
          </li>
          <li>
            <Link to="/gallery/fireplace-accessories">
              Fireplace Accessories
            </Link>
          </li>
          <li>
            <Link to="/gallery/furniture">Furniture</Link>
          </li>
          <li>
            <Link to="/gallery/door-and-window-hardware">
              Door and Window Hardware
            </Link>
          </li>
          <li>
            <Link to="/gallery/kitchen-and-bath">Kitchen & Bath</Link>
          </li>
          <li>
            <Link to="/gallery/other-items">Other Items</Link>
          </li>
          <li>
            <Link to="/gallery/outdoor">Outdoor</Link>
          </li>
          <li>
            <Link to="/gallery/wagon-hardware">Wagon Hardware</Link>
          </li>
          <li>
            <Link to="/gallery/tools-for-blacksmiths">
              Tools for Blacksmiths
            </Link>
          </li>
        </ul>
      </article>
    </Layout>
  )
}

export default Gallery
